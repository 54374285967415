import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
  colors: {
    brand: {
      100: "#83D0F5",
      200: "#06428E",
      300: "#23246C",
      400: "#0075BF",
      orange: "#F49600",
    },
  },
  fonts: {
    heading: "Sansation, Helvetica, Arial, sans-serif",
    body: "Helvetica, Arial, sans-serif",
  },
  styles: {
    global: {
      a: {
        textDecor: "none",
      },
    },
  },
})

export default theme

const React = require("react")
const { LocaleProvider } = require("./src/context/locale/LocaleProvider")
const { AuthProvider } = require("./src/providers/firebase/auth")

exports.wrapPageElement = ({ element }) => {
  return (
    <AuthProvider>
      <LocaleProvider>{element}</LocaleProvider>
    </AuthProvider>
  )
}

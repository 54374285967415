import { useContext } from "react"

import { AuthContext } from "./context"
import { AuthProvider } from "./provider"

/**
 * useAuth()
 * A hook for handling user data from the Firebase
 * provider.
 */

function useAuth() {
  return useContext(AuthContext)
}

export { AuthProvider, useAuth }

import React, { useEffect, useState } from "react"

const LocaleContext = React.createContext({ locale: "en" })

function localeReducer(state, action) {
  switch (action.type) {
    case "set": {
      localStorage.setItem("locale", action.locale)

      return {
        locale: action.locale,
      }
    }

    default: {
      throw new Error(`Unhandled action type in Locale Context`)
    }
  }
}

function LocaleProvider({ children }) {
  const [locale, setLocale] = useState("en")

  useEffect(() => {
    const selectedLocale = localStorage.getItem("locale")

    if (selectedLocale) {
      setLocale(selectedLocale)
    }
  }, [])

  const [state, dispatch] = React.useReducer(localeReducer, {
    locale,
  })

  const value = { state, dispatch }

  return (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
  )
}

function useLocale() {
  const context = React.useContext(LocaleContext)

  if (context === undefined) {
    throw new Error("useLocale must be used within a LocaleProvider")
  }
  return context
}

export { LocaleProvider, useLocale }

import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"

if (typeof window !== "undefined" && !firebase.apps.length) {
  const firebaseConfig = {
    apiKey: process.env.GATSBY_FB_API_KEY,
    authDomain: process.env.GATSBY_FB_AUTH_DOMAIN,
    // databaseURL: process.env.GATSBY_FB_DB_URL,
    projectId: process.env.GATSBY_FB_PROJECT_ID,
    storageBucket: process.env.GATSBY_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_FB_MESSAGING_SENDER_ID,
    appId: process.env.GATSBY_FB_APP_ID,
  }

  firebase.initializeApp(firebaseConfig)
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
}

export { firebase }

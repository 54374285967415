import React, { useState, useEffect } from "react"

import { firebase } from "../../../lib/firebase/client"
import { AuthContext } from "./context"

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null)
  const [status, setStatus] = useState("authenticating")
  const [group, setGroup] = useState()

  useEffect(() => {
    return firebase.auth().onIdTokenChanged(user => {
      if (!user) {
        setUser(null)
        setStatus("unauthenticated")
        return
      }

      user.getIdTokenResult().then(() => {
        setUser(user)
        setStatus("authenticated")

        // Define the user group
        if (!group) {
          const db = firebase.firestore()

          db.doc(`users/${user.uid}`)
            .get()
            .then(doc => {
              const path = doc.data().group.path

              db.doc(path)
                .get()
                .then(doc => {
                  setGroup(doc.data())
                })
                .catch(err => {
                  console.error("Unable to retrieve group", err)
                })
            })
            .catch(err => console.log("err", err))
        }
      })
    })
  }, [])

  return (
    <AuthContext.Provider value={{ user, status, group }}>
      {children}
    </AuthContext.Provider>
  )
}
